$primary: #326DB5;
$secondary: #1C1D39;

// .amplify-button[data-variation='primary'] {
//   background: $primary;
// }

@import '~bootstrap/scss/bootstrap.scss';
@import 'buildinfo.scss';

[data-amplify-authenticator][data-variation=modal] {
  &:after {
    content: $buildinfo;
    position: absolute;
    right: 20px;
    bottom: 15px;
  }
}

.amplify-passwordfield {
  flex-direction: row;
}

.amplify-passwordfield .amplify-field-group {
  width: 100%;
}

.amplify-passwordfield::after {
  content: 'ⓘ';
  font-size: 1.4em;
  padding-left: .4em;
}

.amplify-passwordfield:hover .amplify-field-group:not(:hover)::after {
  content: 'A passphrase is like a password, but longer and with multiple words';

  display: block;
  position: absolute;
  right: -150px;
  width: 300px;
  top: -60px;

  background: black;
  color: white;
  border-radius: 3px;
  padding: 5px 10px;
}

.amplify-passwordfield {
  position: relative;
}
